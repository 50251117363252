$dark-grey:		#1a1a1a;

body{
	background-color: #000;
	color: $light;
}




.navbar.navbar-dark{
	background-color: #000;

	.nav-item.active{
		position: relative;
		@include media-breakpoint-only(sm) {
		}
		@include media-breakpoint-only(md) {
		}

		@include media-breakpoint-only(lg) {
			&:after{
				content: '';
				width: 0; 
				height: 0; 
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-top: 20px solid #000;
				position: absolute;
				bottom: -28px;
				left: 41%;
				z-index: 999;
			}
		}

		@include media-breakpoint-only(xl) {
			&:after{
				content: '';
				width: 0; 
				height: 0; 
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-top: 20px solid #000;
				position: absolute;
				bottom: -28px;
				left: 41%;
				z-index: 999;
			}
		}

	}




}

.footer,.bottom-footer{
	a{
		&:link,&:visited,&:focus,&:active,&:hover{
			color: #fff;
		}
	}
}

.bottom-footer{
	background-color: $dark-grey;
}

iframe{

  &.map, &.youtube{
    border:none;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;

  }

}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 

  &.home{
    margin-top: 30px;
    margin-bottom: 30px;
  }

} 
.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}